import {Transfer} from "antd";
import React from "react";

export default function EntityTransfer(props: EntityTransferProps) {

    return <>
        <Transfer
            className={props.className}
            showSearch
            listStyle={{
                width: 300,
                height: 400,
            }}
            render={item => item.title}
            targetKeys={props.value.map(v => v.key)}
            {...props}
        />
    </>
}

interface EntityTransferProps {
    className?: string,
    dataSource: any[],
    onChange: (nextTargetKeys: string[], direction: string, moveKeys: string[]) => void,
    titles?: string[],
    value: any[]
}