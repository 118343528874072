// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wmoHG4M_bSS88JM5ceBz{width:50px}.HpIo8IJy7bImRfumBguh{display:flex}\n", "",{"version":3,"sources":["webpack://./src/components/colorPicker/colorPicker.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAW,CACd,sBAGG,YAAa","sourcesContent":[".colorInput {\n    width: 50px;\n}\n\n.container {\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorInput": "wmoHG4M_bSS88JM5ceBz",
	"container": "HpIo8IJy7bImRfumBguh"
};
export default ___CSS_LOADER_EXPORT___;
