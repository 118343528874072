import {Upload, message, Button, Tooltip} from 'antd';
import {DownloadOutlined, LoadingOutlined, UploadOutlined} from '@ant-design/icons';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {RcFile, UploadChangeParam, UploadFile} from 'antd/lib/upload/interface';
import fileDownload from "js-file-download";
import {FileUploaderProps} from './fileUploaderProps';

const mapListToListWithDownloadButton = (files?: UploadFile[]) => {
    if (!files) {
        return [];
    }

    return files.map(f => {
        f.status = 'done'; // set status to show download-icon
        return f;
    });
}

const FileUploader = forwardRef((props: FileUploaderProps, ref: React.ForwardedRef<any>) => {
    const [fileList, setFileList] = useState<UploadFile[]>(mapListToListWithDownloadButton(props.defaultFileList));
    const filesToRemove = useRef<UploadFile[]>([]);
    const [pendingDownload, setPendingDownload] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState<any>({});

    useEffect(() => {
        setFileList(props.defaultFileList || []);
    }, [props.defaultFileList]);

    useImperativeHandle(ref, () => ({
        async onFormFinish() {
            setIsLoading(true);

            try {
                const result = await Promise.all([
                    ...fileList.map(f => uploadFile(f)),
                    ...filesToRemove.current.map(f => removeFile(f))
                ]);

                return result.filter(r => r !== undefined)[0];
            } finally {
                setIsLoading(false);
            }
        }
    }));

    const refreshFileList = () => {
        setFileList([...fileList]);
    }

    const getFileByUid = (uid: string) => {
        return fileList.find(f => f.uid === uid)!;
    }

    const removeFile = async (file: UploadFile) => {

        if (+file.uid > 0) {
            await props.fileDeleteFunction?.(file);
        }

        const fileToRemoveIndex = filesToRemove.current.indexOf(file);

        if (fileToRemoveIndex > -1) {
            filesToRemove.current.splice(fileToRemoveIndex, 1);
        }

        const fileIndex = fileList.indexOf(file);

        if (fileIndex > -1) {
            fileList.splice(fileIndex, 1);
        }
    }

    function updateProgress(uid: string, percent: number) {
        const newProgress = { ...progress };
        newProgress[uid] = percent;

        setProgress(newProgress);
    }

    const uploadFile = async (file: UploadFile) => {
        if (+file.uid > 0) {
            return;
        }

        let fileInList = getFileByUid(file.uid);
        let isFileFromList = true;

        if (!fileInList) {
            isFileFromList = false;
            fileInList = file;
        }

        fileInList.status = "uploading";
        fileInList.percent = 0;
        refreshFileList();

        const httpRequestOptions = {
            onUploadProgress: (event: any) => {
                const percent = (event.loaded / event.total) * 100;
                updateProgress(fileInList.uid, percent);
            }
        }

        try {
            const result = await props.fileUploadFunction(file.originFileObj, httpRequestOptions, file);
            let newlyCreatedFileId = +result.data.id!;

            fileInList.uid = newlyCreatedFileId.toString();
            fileInList.status = "success";
            fileInList.url = "https://"; // actual download is handled by onDownload

            if (!isFileFromList) {
                fileList.push(file);
            }

            refreshFileList();
            props.onChange?.(fileList.map(f => f.uid), fileList);
        } catch (error) {
            fileInList.status = "error";
            fileInList.error = error;
            refreshFileList();

            return error;
        }
    }

    function beforeUpload(file: RcFile) {
        if (props.sizeLimitMb) {
            const isSizeOverLimit = file.size / 1024 / 1024 < props.sizeLimitMb;
            if (!isSizeOverLimit) {
                message.error(props.sizeValidationMessage || `Image must be smaller than ${props.sizeLimitMb}MB`);
            }
        }

        return false;
    }

    const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
        setFileList(info.fileList);
        props.onChange?.(info.fileList.map(f => f.uid), info.fileList);
    }

    const onRemove = async (file: UploadFile) => {
        if (+file.uid > 0 && !filesToRemove.current.some(f => f.uid === file.uid)) {
            filesToRemove.current.push(file);
        }
    }

    const onDownload = async (file: UploadFile) => {
        const id = +file.uid;
        if (!id) {
            throw Error(`Can't handle file without proper numeric ID. File id: ${id}`)
        }
        if (pendingDownload) {
            return;
        }

        setPendingDownload(true);

        props.fileDownloadFunction(id)
            .then((response:any) => {
                const contentType = response.headers["content-type"];

                if (!props.openAsPreview) {
                    fileDownload(response.data, file.name || 'download-file', contentType);
                    
                    return;
                }
                
                const blob = new Blob([response.data!], {type: contentType || 'application/octet-stream'});
                const blobUrl = window.URL.createObjectURL(blob);
                window.open(blobUrl, "_blank");
            }).finally(() => {
            setPendingDownload(false);
        });
    }

    const downloadIcon = () => {
        return pendingDownload
            ? <LoadingOutlined disabled onClick={(event) => {
                event.preventDefault()
            }}/>
            : <DownloadOutlined/>
    }

    // handle maxCount manually as Upload component doesn't fire the onRemove command when replacing files after exceeding the maxCount,
    // possibly leading to orphaned files on the db
    const maxCountReached = fileList.length >= (props.maxCount || Number.MAX_VALUE);

    const files = fileList.map(f => ({ ...f, percent: progress[f.uid] }));

    return (
        <>
            <Upload
                className="file-uploader"
                disabled={isLoading || props.readonly}
                openFileDialogOnClick={!maxCountReached}
                name="file"
                beforeUpload={beforeUpload}
                onRemove={onRemove}
                onDownload={onDownload}
                onChange={onChange}
                fileList={files}
                accept={props.accept || ''}
                showUploadList={{
                    showDownloadIcon: true,
                    downloadIcon: downloadIcon(),
                    showRemoveIcon: true
                }}
                {...props.fileUploaderProps}
            >
                {props.readonly ? null : maxCountReached ? (props.hideUploadButton ? null : <Tooltip title={props.uploadFilesCountMessage || `Can't upload more than ${props.maxCount} files`}>
                        <Button loading={isLoading} disabled={true} icon={<UploadOutlined/>}>{props.buttonLabel || "Click to Upload"}</Button>
                    </Tooltip>)
                    : <Button loading={isLoading} disabled={isLoading} icon={<UploadOutlined/>}>{props.buttonLabel || "Click to Upload"}</Button>
                }
            </Upload>
        </>
    );
});

export default FileUploader;
