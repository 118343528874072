import {Breadcrumb} from "antd";
import useCurrentRoutingPath from "../../hooks/useCurrentRoutingPath";
import React, {createContext, useContext} from "react";
import {Link} from "react-router-dom";

const BreadcrumbContext = createContext<string | null>(null);

export default function CrudBreadcrumb({routingDefinitions}: CrudBreadcrumbProps) {
    const route = useCurrentRoutingPath(routingDefinitions);
    const items = route?.breadcrumbEntries;
    const context = useContext(BreadcrumbContext);

    return <Breadcrumb style={{margin: '16px 0'}}>
        {items?.map((item, i) => <Breadcrumb.Item key={i}>
            {item.route ? <Link to={item.route}>{item.name}</Link> : <>{context ?? item.name}</>}
        </Breadcrumb.Item>)}
    </Breadcrumb>;
}

export function BreadcrumbProvider(props: BreadcrumbContext) {
    return <BreadcrumbContext.Provider value={props.contextValue}>
        {props.children}
    </BreadcrumbContext.Provider>
}

export interface BreadcrumbContext {
    contextValue: string;
    children: React.ReactNode;
}

export interface CrudBreadcrumbProps {
    routingDefinitions: { [key: string]: RoutingDefinition };
}

export interface RoutingDefinition {
    route: string;
    breadcrumbEntries?: BreadcrumbEntry[];
}

export interface BreadcrumbEntry {
    name: string,
    route?: string
}
