import { Layout } from "antd";
import React from "react";
import { useState } from "react";

const { Content, Footer, Sider } = Layout;

export default function CrudLayout(props: CrudLayoutProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  return <Layout style={{ minHeight: '100vh' }}>
    {!props.showSider ? null :
      <Sider breakpoint="sm" collapsible collapsed={isCollapsed} onCollapse={() => setIsCollapsed(!isCollapsed)}>
        {props.siderMenu}
      </Sider>
    }
    <Layout className="site-layout">
      <>
      {props.header}
      </>
      <Content>
        <>
        {props.breadcrumb}
        </>
        <>
        {props.children}
        </>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        {props.footerContent}
      </Footer>
    </Layout>
  </Layout>;
}

export interface CrudLayoutProps {
  children: JSX.Element;
  breadcrumb: JSX.Element;
  siderMenu: JSX.Element;
  header: JSX.Element;
  footerContent: JSX.Element;
  showSider: boolean;
}
