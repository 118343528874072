import Paragraph from "antd/lib/typography/Paragraph";
import { useEffect, useState } from "react";
import InlineEditable from "./inlineEditable";
import { Typography } from "antd";

const {Text} = Typography;

export default function InlineEditableText(props: MultilineInlineEditableTextProps) {
    const [value, setValue] = useState<string>(props.value);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onSave = (v: string) => {
        setValue(v);
        props.onSave(v);
        setIsEditing(false);
    }

    const onCancel = () => {
        setIsEditing(false);
    };

    if (isEditing) {
        return <InlineEditable singleLine={true} value={value} onSave={onSave} onCancel={onCancel} />;
    }

    const editable = props.editable ? {
        editing: isEditing,
        onStart: () => setIsEditing(true)
    } : false;

    return <Paragraph ellipsis={props.ellipsis} style={{lineBreak:"anywhere", whiteSpace: "pre-wrap" }} editable={editable}>
            {!value && <Text type="secondary">{props.placeholder}</Text>}
            {value}
    </Paragraph>;
}

interface MultilineInlineEditableTextProps {
    value: string,
    onSave: (value: string) => any,
    editable: boolean;
    ellipsis?: any;
    placeholder?: string
}
