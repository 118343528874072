import {FormInstance} from "antd/lib/form";
import {Alert, Button, Form, Space} from "antd";
import React, {useRef, useState} from "react";
import styles from "./crudForm.module.scss";
import {CrudFormItemsWrapper} from "./crudFormItemsWrapper";
import {CrudFormProps} from "./crudFormProps";

const defaultLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 19},
};

const defaultTailLayout = {
    wrapperCol: {offset: 5, span: 19},
};

function FormHasErrors(form: FormInstance<any>) {
    return !!form.getFieldsError().filter(({errors}) => errors.length).length;
}

function mapResponseErrorToString(response: any): string {
    if (typeof response === "string") {
        return response;
    }

    if (response.errors) {
        return Object.values(response.errors as { [key: string]: string[] }).reduce((a, b) => [...a, ...b]).join(". ");
    }

    return response.error;
}

type CrudFormItemsWrapperHandle = React.ElementRef<typeof CrudFormItemsWrapper>;

export default function CrudForm<TSubmit, TInitial>(props: CrudFormProps<TSubmit, TInitial>) {
    const [form] = Form.useForm(props.form);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isTouched, setIsTouched] = useState(false);
    const extraActionsPosition = props.extraActionsPosition || "right";
    const itemWrapperRef = useRef<CrudFormItemsWrapperHandle>();
    const initialDisable = !props.initialValues && !isTouched;

    const submitButton = <Button
        {...props?.submitButtonProps}
        type="primary"
        htmlType="submit"
        loading={isLoading}
        disabled={initialDisable || isLoading || props.isSubmitDisabled}
    >
        {props.saveButtonTitle || "Save"}
    </Button>
    
    const onFinish = async (value: TSubmit) => {
        setErrorMessage(undefined);
        setIsLoading(true);
        props.setIsLoadingExternal?.(true);

        let error = await itemWrapperRef.current.onFormFinish();

        if (!error) {
            error = await props.onSubmit(form.getFieldsValue());
        }

        setIsLoading(false);
        props.setIsLoadingExternal?.(false);

        const fieldNames = Object.keys(form.getFieldsValue());
        form.isFieldsTouched(fieldNames, false);
        setIsTouched(false);
        if (!error) {
            return;
        }
        setErrorMessage(mapResponseErrorToString(error));
    };

    const submitButtonWrapperClassName = extraActionsPosition === "left" ? styles.submitButtonWrapper : undefined;

    return <>
        <Form
            {...defaultLayout}
            {...props?.formProps}
            form={form}
            onFinish={onFinish}
            onFinishFailed={props.onFinishFailed}
            initialValues={props.initialValues}
            onValuesChange={() => setIsTouched(true)}
        >
            {props.headFormItems}
            <CrudFormItemsWrapper ref={itemWrapperRef} formItems={props.formItems}/>
            {props.underInputActions
                ? <Space className={styles.underInputActionWrapper}>
                    {props.underInputActions}
                </Space>
                : null}
            <Form.Item {...defaultTailLayout} {...props?.tailProps} shouldUpdate>
                {() => <>
                    <div className={styles.buttonsLine}>
                        {extraActionsPosition === "left"
                            ? <Space className={styles.buttonsExtra}>
                                {props.extraActions}
                            </Space>
                            : null}
                        {props.extraActions ? <Space className={submitButtonWrapperClassName}>
                            {submitButton}
                            {extraActionsPosition === "right"
                                ? props.extraActions
                                : null}
                        </Space> : submitButton}

                    </div>
                </>}
            </Form.Item>
            {errorMessage ? <Alert className={styles.alert} message={errorMessage} type="error"/> : null}
        </Form>
    </>;
}
