import {Button, Empty} from "antd";
import React from "react";
import styles from "./emptyScreenInfo.module.scss"
import {EmptyScreenInfoProps} from "./emptyScreenInfoProps";


export default function EmptyScreenInfo(props: EmptyScreenInfoProps) {

    return <div className={styles.emptyWrapper}>
        <Empty
            description={
                <span>
                        {props.text}
                    </span>
            }
        >
            <Button onClick={props.onButtonClick} type="primary">{props.buttonText}</Button>
        </Empty>
    </div>;
}
