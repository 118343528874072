// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A0TgJZYguqHxwivrLD_j{height:80%;display:flex;align-items:center;justify-content:center}\n", "",{"version":3,"sources":["webpack://./src/components/emptyScreenInfo/emptyScreenInfo.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAW,CACX,YAAa,CACb,kBAAmB,CACnB,sBAAuB","sourcesContent":[".emptyWrapper {\n    height: 80%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyWrapper": "A0TgJZYguqHxwivrLD_j"
};
export default ___CSS_LOADER_EXPORT___;
