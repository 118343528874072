import { CheckOutlined, CloseOutlined, EnterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import classNames from "classnames";
import React from "react";

interface EditableProps {
    prefixCls?: string;
    value: string;
    ['aria-label']?: string;
    onSave: (value: string) => void;
    onCancel: () => void;
    onEnd?: () => void;
    className?: string;
    style?: React.CSSProperties;
    maxLength?: number;
    autoSize?: boolean;
    enterIcon?: React.ReactNode;
    component?: string;
    onChange?: (value: string) => any
  }
  
  const InlineEditable: React.FC<EditableProps & { singleLine: boolean }> = ({
    'aria-label': ariaLabel,
    className,
    style,
    maxLength,
    autoSize = true,
    value,
    onSave,
    onCancel,
    onEnd,
    onChange,
    singleLine
  }) => {
    const ref = React.useRef<any>();
    const prefixCls = "ant-typography";
  
    const inComposition = React.useRef(false);
  
    const [current, setCurrent] = React.useState(value);
  
    React.useEffect(() => {
      setCurrent(value);
    }, [value]);
  
    React.useEffect(() => {
      if (ref.current && ref.current.resizableTextArea) {
        const { textArea } = ref.current.resizableTextArea;
        textArea.focus();
        const { length } = textArea.value;
        textArea.setSelectionRange(length, length);
      }
    }, []);
  
    const onChangeInternal: React.ChangeEventHandler<HTMLTextAreaElement> = ({ target }) => {
      const value = singleLine ? target.value.replace(/[\n\r]/g, '') : target.value;
      onChange?.(value);
      setCurrent(value);
    };
  
    const onCompositionStart = () => {
      inComposition.current = true;
    };
  
    const onCompositionEnd = () => {
      inComposition.current = false;
    };
  
    const confirmChange = () => {
      onSave(current.trim());
    };
  
    const onBlur: React.FocusEventHandler<HTMLTextAreaElement> = () => {
      //onCancel();
    };
  
    const textAreaClassName = classNames(
      prefixCls,
      `${prefixCls}-edit-content`,
      className,
    );

    const onOkClick = () => {
      confirmChange();
      onEnd?.();
    };

    const onCancelClick = () => {
      onCancel();
    }
  
    return (
      <div className={textAreaClassName} style={{ width: "100%", ...style}}>
        <TextArea
          ref={ref as any}
          maxLength={maxLength}
          value={current}
          onCompositionStart={onCompositionStart}
          onCompositionEnd={onCompositionEnd}
          onBlur={onBlur}
          onChange={onChangeInternal}
          aria-label={ariaLabel}
          rows={1}
          autoSize={autoSize}
        />
        <div style={{ position: "absolute", bottom: -24, right: 10 }}>
            <Button style={{ height: 33, color: "white" }} type="primary" icon={<CheckOutlined />} onClick={onOkClick} />
            <Button style={{ marginLeft: 5, height: 33 }} icon={<CloseOutlined />} onClick={onCancelClick} />
        </div>
      </div>
    );
  };
  
  export default InlineEditable;
