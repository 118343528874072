import {useAuth} from "oidc-react";
import React, { ReactElement } from "react";
import {Route, RouteProps} from "react-router";

export default function ProtectedRoute(props: RouteProps & ProtectedRouteProps) {
    const auth = useAuth();

    if (auth.isLoading) {
        return props.loadingIndicator;
    }

    if (!auth.userData) {
        auth.userManager.signinSilent().catch(() => auth.signIn());

        return props.loadingIndicator;
    }

    return <Route {...props} />
}

export interface ProtectedRouteProps {
    loadingIndicator?: ReactElement;
}