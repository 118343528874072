import {RoutingDefinition} from "components/crudBreadcrumb/crudBreadcrumb";
import {useMemo} from "react";
import {matchPath, useLocation} from "react-router-dom";

export default function useCurrentRoutingPath(routingPaths: { [key: string]: RoutingDefinition }): RoutingDefinition | null {
  const location = useLocation();

  return useMemo(() => {
    const key = Object.keys(routingPaths).find(k => matchPath({path: routingPaths[k].route}, location.pathname));

    return key ? routingPaths[key] : null;
  }, [location.pathname]);
}
