import {Button, ButtonProps, Popconfirm, PopconfirmProps} from "antd";
import React, {useState} from "react";

export default function AsyncButtonPopconfirm(
    props: (PopconfirmProps & ButtonProps & PopconfirmProps & AsyncButtonPopconfirmProps)
) {
    const [isLoading, setIsLoading] = useState(false);

    const onClick = async (event: any) => {
        setIsLoading(true);
        try {
            await props.onConfirm?.(event);
        } finally {
            setIsLoading(false);
        }
    };

    return <>
        <Popconfirm
            title={props.title}
            onConfirm={onClick}
            onCancel={props.onCancel}
            okText={props.okText}
            cancelText={props.cancelText}
            {...props.popconfirmProps}
        >
            <Button
                children={props.children}
                danger={props.danger}
                type={props.type}
                loading={isLoading}
                disabled={isLoading}
                {...props.buttonProps}
            />
        </Popconfirm>
    </>
}

interface AsyncButtonPopconfirmProps {
    buttonProps?: ButtonProps,
    popconfirmProps?: PopconfirmProps
}