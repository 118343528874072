import {Button, ButtonProps, Skeleton, Space, Spin, Table, TablePaginationConfig} from "antd";
import { SpinIndicator } from "antd/lib/spin";
import {ColumnsType, ColumnType} from "antd/lib/table";
import { TableLocale } from "antd/lib/table/interface";
import React, {ReactNode, useState} from "react";
import {humanize} from "../../helpers/stringHelpers";
import AsyncButtonPopconfirm from "../asyncButtonPopconfirm/asyncButtonPopconfirm";

export default function CrudTable<T extends { id?: number }>(props: CrudTableProps<T>) {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const actionsColumn: ColumnType<T> = {
        key: "actions",
        title: "",
        fixed: props.actionsFixed === undefined ? "right" : props.actionsFixed,
        render: (value: any, record: T) => {
            return <>
                <Space>
                    {props.onEditClicked ? <Button
                        onClick={() => props.onEditClicked!(record.id!)}
                    >{props.editButtonTitle || "Edit"}</Button> : null}
                    {props.onDeleteClicked ? <AsyncButtonPopconfirm
                        danger
                        title={props.deleteConfirmMessage || "Are you sure to delete this item?"}
                        onConfirm={async () => {
                            setIsLoading(true);
                            try {
                                await props.onDeleteClicked!(record.id!);
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                        onCancel={() => {
                        }}
                        okText={"Ok"}
                        cancelText={props.cancelButtonTitle || "Cancel"}
                    >
                        {props.deleteButtonTitle || "Delete"}
                    </AsyncButtonPopconfirm> : null}

                    {props.customRowActions?.(record)}
                </Space>
            </>

        }
    };

    props.columns.forEach(c => {
        const column = c as ColumnType<T>;
        if (!column.dataIndex) {
            column.dataIndex = column.key;
        }

        if (!column.title) {
            column.title = humanize(column.key as string);
        }
    });

    const columns = [...props.columns, actionsColumn];

    return <Spin indicator={props.loadingIndicator} spinning={!props.data || isLoading || props.isLoading || false} delay={props.loadingIndicatorDelay || 400}>
        <Table pagination={props.pagination} locale={props.locale} columns={columns} dataSource={props.data} rowKey="id" scroll={{x: true}}></Table>
        <br />
        <Space>
            {props.onAddClicked ? <Button
                type="primary"
                onClick={props.onAddClicked}
                {...props.submitButtonProps}
            >
                {props.addButtonTitle || "+ Add"}
            </Button> : null}
            {props.customActions?.()}
        </Space>
    </Spin>;
}

interface CrudTableProps<T> {
    loadingIndicatorDelay?: number;
    isLoading?: boolean;
    loadingIndicator?: SpinIndicator;
    data: T[] | null,
    onAddClicked?: React.MouseEventHandler<HTMLElement>,
    onEditClicked?: (id: number) => any,
    onDeleteClicked?: (id: number) => any,
    columns: ColumnsType<T>,
    customRowActions?: (record: T) => ReactNode,
    customActions?: () => ReactNode,
    actionsFixed?: 'left' | 'right' | boolean,
    addButtonTitle?: string,
    editButtonTitle?: string,
    deleteButtonTitle?: string,
    deleteConfirmMessage?: string,
    cancelButtonTitle?: string
    locale?: TableLocale,
    submitButtonProps?: ButtonProps & React.RefAttributes<HTMLElement>,
    pagination?: TablePaginationConfig
}

export interface CrudTableWrapperProps<T> {
    data: T[] | null,
    onAddClicked?: () => any,
    onEditClicked?: (id: number) => any,
    onDeleteClicked?: (id: number) => any,
    isLoading?: boolean
}
