import {Input} from "antd";
import React, {useEffect, useState} from "react";
import styles from "./colorPicker.module.scss";

export default function ColorPicker({onChange, value}: any) {
    const [colorValue, setColorValue] = useState<string>(value);

    const onChangeInternal = (element: React.ChangeEvent<HTMLInputElement>) => {
        setColorValue(element.target.value);
        onChange?.(element);
    };

    useEffect(() => {
        setColorValue(value);
    }, [value]);

    const displayValue = colorValue || "#000000";

    return <div className={styles.container}>
        <Input className={styles.colorInput} type="color" onChange={onChangeInternal} value={displayValue}></Input>
        <Input onChange={onChangeInternal} value={displayValue}></Input>
    </div>;
}