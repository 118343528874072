import {Switch, SwitchProps} from "antd";
import React, {useState} from "react";

export default function AsyncSwitch(props: AsyncSwitchProps) {
    const [isLoading, setIsLoading] = useState(false);

    const onClick = async (checked: boolean, event: any) => {
        setIsLoading(true);
        try {
            await props.onClick?.(checked, event);
        } finally {
            setIsLoading(false);
        }
    };

    return <Switch
        {...props}
        checked={props.value}
        onClick={onClick}
        loading={isLoading}
    />
}

interface AsyncSwitchProps extends SwitchProps {
    value?: boolean
}