// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pKhQGB7Y7Q75KpYRS1zO{margin-top:20px}.vNIaEY7HowXUUiHnF4bC{margin-bottom:10px}.zh0gbZNvnMLLYGwdaGTA .xS_40cT2xznbh0Bq4q27{display:inline-block}.zh0gbZNvnMLLYGwdaGTA .kmjDf6gOBU5nfXv8hOzQ{float:right}\n", "",{"version":3,"sources":["webpack://./src/components/crudForm/crudForm.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAgB,CACnB,sBAGG,kBAAmB,CACtB,4CAIO,oBAAqB,CAF7B,4CAKQ,WACJ","sourcesContent":[".alert {\n    margin-top: 20px;\n}\n\n.underInputActionWrapper {\n    margin-bottom: 10px;\n}\n\n.buttonsLine {\n    .buttonsExtra {\n        display: inline-block;\n    }\n    .submitButtonWrapper {\n        float:right\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "pKhQGB7Y7Q75KpYRS1zO",
	"underInputActionWrapper": "vNIaEY7HowXUUiHnF4bC",
	"buttonsLine": "zh0gbZNvnMLLYGwdaGTA",
	"buttonsExtra": "xS_40cT2xznbh0Bq4q27",
	"submitButtonWrapper": "kmjDf6gOBU5nfXv8hOzQ"
};
export default ___CSS_LOADER_EXPORT___;
